.nav-link,
.dropdown-item {
  transition: var(--transition) !important;
}
.dropdown-item:hover {
  background-color: var(--blue) !important;
}
.nav-link:hover {
  color: var(--blue) !important;
}
.navbar-toggler {
  background-color: white !important;
}